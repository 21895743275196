<template>
  <auth-layout :background-image="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("auth.forgot-password.welcome") }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t("auth.forgot-password.call-to-action") }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="form">
      <b-form class="auth-forgot-password-form mt-2">
        <!-- email -->
        <b-form-group :label="$t('fields.email')" label-for="register-email">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-input
              id="register-email"
              v-model="form.email"
              name="email"
              type="email"
              :state="errors.length > 0 ? false : null"
              placeholder="example@facerecognition.io"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="primary"
          block
          type="submit"
          @click.prevent="validationForm"
          :disabled="loading"
        >
          <b-spinner small v-if="loading" />
          <span class="sr-only" v-if="loading">
            {{ $t("general.loading") }}</span
          >
          <span v-if="!loading">{{ $t("auth.forgot-password.action") }}</span>
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <router-link :to="{ name: 'login' }">
        <feather-icon icon="ChevronLeftIcon" />
        {{ $t("auth.forgot-password.redirect-sign-in-link") }}
      </router-link>
    </p>
  </auth-layout>
</template>

<script>
import AuthLayout from "./components/AuthLayout.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from "@/store/index";

import { forgotPassword } from "@/api/auth.api";

export default {
  components: { AuthLayout, ValidationProvider, ValidationObserver },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  data() {
    return {
      loading: false,
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      form: {
        password: "",
        email: "",
      },
      required,
      email,
    };
  },
  methods: {
    validationForm() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;

        this.loading = true;
        const result = await forgotPassword(this.form);
        if (result.error) {
          this.$refs.form.setErrors(result.error);
        } else if (result.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("auth.forgot-password.success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style>
</style>